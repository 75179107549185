import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {CourseForm} from "../forms";
import {courseService} from "../../services";
import {forms} from "@atttomyx/shared-utils";
import {STATUS_DRAFT} from "../../constants";

const freshData = () => {
    return {
        name: null,
        imageUrl: null,
        status: STATUS_DRAFT,
        valid: false,
    }
};

const AddCourseDialog = (props) => {
    const { snackbar, onCancel, onSave } = props;
    const [ data, setData ] = useState(freshData());
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess(saved.name + " saved");
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        const course = {
            name: data.name,
            imageUrl: null,
            status: data.status,
        };

        courseService.createCourse(course, success, failure);
    }

    return <Dialog
        open={true}
        aria-labelledby="add-course-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Add Course
        </ClosableDialogTitle>
        <DialogContent className="add-course-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <CourseForm
                    data={data}
                    onChange={setData}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(freshData(), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default AddCourseDialog;
