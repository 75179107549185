import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {QuestionForm} from "../forms";
import {questionService} from "../../services";
import {forms} from "@atttomyx/shared-utils";
import {ANSWER_TYPE_STRING} from "../../constants";

const toData = (order) => {
    return {
        ask: null,
        type: ANSWER_TYPE_STRING,
        order: order,
        valid: false,
    }
};

const AddQuestionDialog = (props) => {
    const { snackbar, questionnaireId, order, onCancel, onSave } = props;
    const [ data, setData ] = useState(toData(order));
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess(saved.ask + " saved");
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        const question = {
            questionnaireId: questionnaireId,
            ask: data.ask,
            type: data.type,
            order: data.order,
        };

        questionService.createQuestion(question, success, failure);
    }

    return <Dialog
        open={true}
        aria-labelledby="add-question-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Add Question
        </ClosableDialogTitle>
        <DialogContent className="add-question-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <QuestionForm
                    data={data}
                    onChange={setData}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(toData(order), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default AddQuestionDialog;
