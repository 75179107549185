import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {SubmissionForm} from "../forms";
import {submissionService} from "../../services";
import {arrays, forms, strings} from "@atttomyx/shared-utils";

const toData = (questions, submission) => {
    const data = {
        valid: false,
    };

    if (submission) {
        const previous = {}

        submission.questions.forEach(question => {
            previous[question.ask] = question.reply;
        });

        questions.forEach(question => {
            data[question.id] = previous[question.ask];
        });

        let valid = false;
        questions.forEach(question => {
            valid = valid || strings.isNotBlank(data[question.id]);
        });
        data.valid = valid;
    }

    return data;
};

const SubmissionDialog = (props) => {
    const { snackbar, questionnaire, questions, submission, onCancel, onSave } = props;
    const [ data, setData ] = useState(toData(questions, submission));
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess(questionnaire.title + " saved");
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        const questionsAndAnswers = [];

        questions.forEach(question => {
            arrays.addTo(questionsAndAnswers, {
                ask: question.ask,
                reply: data[question.id],
            });
        });

        submissionService.saveSubmission(questionnaire.id, questionsAndAnswers, success, failure);
    };

    return <Dialog
        open={true}
        aria-labelledby="submission-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            {questionnaire.title}
        </ClosableDialogTitle>
        <DialogContent className="submission-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <SubmissionForm
                    data={data}
                    questions={questions}
                    onChange={setData}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(toData(questions, submission), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default SubmissionDialog;
