import React from "react";
import {TextField} from "@mui/material";
import {SelectField} from "@atttomyx/react-components";
import {forms, objects, strings} from "@atttomyx/shared-utils";
import {ANSWER_TYPES} from "../../constants";

const QuestionForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.ask) && strings.isNotBlank(modified.type) && modified.order >= 0;

        onSync(modified);
    };

    return <div className="questionnaire-form">
        <div className="field">
            <TextField
                required={true}
                label="Ask"
                type="text"
                value={forms.sanitizeValue(data.ask)}
                onChange={(event) => onChange("ask", event.target.value)}
            />
        </div>
        <div className="field">
            <SelectField
                required={true}
                label="Type"
                value={forms.sanitizeValue(data.type)}
                onChange={(value) => onChange("type", value)}
                options={ANSWER_TYPES}
            />
        </div>
        <div className="field">
            <TextField
                required={true}
                label="Index"
                type="number"
                value={forms.sanitizeValue(data.order)}
                onChange={(event) => onChange("order", event.target.value)}
                inputProps={{
                    step: 1,
                    min: 0,
                }}
            />
        </div>
    </div>
}

export default QuestionForm;
