import React, {useEffect, useMemo, useState} from "react";
import {withRouter} from "react-router-dom";
import {useFiltered} from "@atttomyx/shared-hooks";
import {CircularProgress, IconButton, Typography} from "@mui/material";
import {ContentCopy as CloneIcon, Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {Cards, ConfirmDeleteDialog, FiltersAccordion, FloatingAddButton} from "@atttomyx/react-components";
import {QuestionnaireCard, SubmissionCard} from "../cards";
import {AddQuestionnaireDialog, SubmissionDialog} from "../dialogs";
import {QuestionnaireFilters} from "../filters";
import {questionnaireService, submissionService} from "../../services";
import {objects} from "@atttomyx/shared-utils";
import {confirm, router} from "@atttomyx/react-utils";
import {PAGE_QUESTIONNAIRE} from "../../constants";

const QuestionnairesPage = (props) => {
    const { history, snackbar, dimensions, user, questionnaires, questions, submissions,
        filters, onSaveQuestionnaire, onCopyQuestionnaire, onDeleteQuestionnaire, onSaveSubmission, onDeleteSubmission } = props;
    const [ questionnaire, setQuestionnaire ] = useState(null);
    const [ submission, setSubmission ] = useState(null);
    const [ cloning, setCloning ] = useState(null);
    const [ showFilters, setShowFilters ] = useState(false);
    const [ showAdd, setShowAdd ] = useState(false);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ showFillOut, setShowFillOut ] = useState(false);
    const filtered = useFiltered(questionnaires, filters);

    const Card = useMemo(() => {
        return user.roles.admin ? QuestionnaireCard : SubmissionCard;
    }, [user]);

    const questionsToUse = useMemo(() => {
        const questionnaireId = questionnaire ? questionnaire.id : null;

        return questionnaireId
            ? questions.filter(question => question.questionnaireId === questionnaireId)
            : [];
    }, [questionnaire, questions]);

    const questionnaireIdToCounts = useMemo(() => {
        const map = {};

        questions.forEach(question => {
            const questionnaireId = question.questionnaireId;
            const existing = objects.defaultIfNullOrUndefined(map[questionnaireId], 0);

            map[questionnaireId] = existing + 1;
        });

        return map;
    }, [questionnaire, questions]);

    const questionnaireIdToSubmission = useMemo(() => {
        const map = {};

        submissions.forEach(submission => {
            const questionnaireId = submission.questionnaireId;

            map[questionnaireId] = submission;
        });

        return map;
    }, [submissions]);

    useEffect(() => {
        if (!questionnaire) {
            setShowAdd(false);
            setShowDelete(false);
        }
    }, [questionnaire]);

    useEffect(() => {
        if (cloning) {
            const success = (result) => {
                snackbar.setSuccess("Questionnaire copied");
                setCloning(null);
                onCopyQuestionnaire(result);
            };

            const failure = (err) => {
                snackbar.setError(err);
                setCloning(null);
            };

            questionnaireService.cloneQuestionnaire(cloning.id, success, failure);
        }
    }, [cloning]);

    return <div className="questionnaiers-page">
        <Typography variant="h5" paragraph={true}>
            Questionnaires
        </Typography>
        <div className="field">
            <FiltersAccordion
                filters={filters}
                form={QuestionnaireFilters}
                open={showFilters}
                onOpen={() => setShowFilters(true)}
                onClose={() => setShowFilters(false)}
                formProps={{
                    user: user,
                }}
            />
        </div>
        <Cards
            items={filtered}
            paging={filters.paging}
            dimensions={dimensions}
            renderer={(questionnaire) =>
                <Card
                    key={questionnaire.id}
                    snackbar={snackbar}
                    questionnaire={questionnaire}
                    numQuestions={questionnaireIdToCounts[questionnaire.id]}
                    submission={questionnaireIdToSubmission[questionnaire.id]}
                    filters={filters}
                >
                    {user.roles.admin ?
                        <>
                            <IconButton color="secondary" title="Delete" className="delete"
                                        onClick={() => {
                                            setQuestionnaire(questionnaire);
                                            setShowDelete(true);
                                        }}>
                                <DeleteIcon/>
                            </IconButton>
                            {cloning && cloning.id === questionnaire.id ?
                                <CircularProgress size="18px"/> :
                                <IconButton color="primary" title="Copy"
                                            onClick={() => confirm.confirm(
                                                `Are you sure you want to make a copy of ${questionnaire.title}?`,
                                                () => setCloning(questionnaire))}>
                                    <CloneIcon/>
                                </IconButton>}
                            <IconButton color="primary" title="Edit"
                                        onClick={() => router.redirectTo(history, PAGE_QUESTIONNAIRE + "/" + questionnaire.id)}>
                                <EditIcon/>
                            </IconButton>
                        </> :
                        <>
                            <IconButton color="secondary" title="Delete" className="delete"
                                        disabled={!questionnaireIdToSubmission[questionnaire.id]}
                                        onClick={() => {
                                            setQuestionnaire(questionnaire);
                                            setSubmission(questionnaireIdToSubmission[questionnaire.id]);
                                            setShowDelete(true);
                                        }}>
                                <DeleteIcon/>
                            </IconButton>
                            <IconButton color="primary" title="Fill out"
                                        onClick={() => {
                                            setQuestionnaire(questionnaire);
                                            setSubmission(questionnaireIdToSubmission[questionnaire.id]);
                                            setShowFillOut(true);
                                        }}>
                                <EditIcon/>
                            </IconButton>
                        </>}
                </Card>}
        />
        {user.roles.admin ?
            <FloatingAddButton
                title="Add questionnaire"
                position="higher"
                onClick={() => {
                    setShowAdd(true);
                }}
            /> : null}
        {showAdd ?
            <AddQuestionnaireDialog
                snackbar={snackbar}
                onCancel={() => {
                    setShowAdd(false);
                }}
                onSave={(saved) => {
                    setShowAdd(false);
                    onSaveQuestionnaire(saved);
                }}
            /> : null}
        {showDelete && submission && questionnaire ?
            <ConfirmDeleteDialog
                snackbar={snackbar}
                title={questionnaire.title}
                onCancel={() => {
                    setShowDelete(false);
                    setQuestionnaire(null);
                    setSubmission(null);
                }}
                onDelete={(submissionId) => {
                    setShowDelete(false);
                    setQuestionnaire(null);
                    setSubmission(null);
                    onDeleteSubmission(submissionId);
                }}
                deleter={(success, failure) => submissionService.deleteSubmission(submission.id, success, failure)}
            /> : showDelete && questionnaire ?
            <ConfirmDeleteDialog
                snackbar={snackbar}
                title={questionnaire.title}
                onCancel={() => {
                    setShowDelete(false);
                    setQuestionnaire(null);
                }}
                onDelete={(questionnaireId) => {
                    setShowDelete(false);
                    setQuestionnaire(null);
                    onDeleteQuestionnaire(questionnaireId);
                }}
                deleter={(success, failure) => questionnaireService.deleteQuestionnaire(questionnaire.id, success, failure)}
            /> : null}
        {showFillOut && questionnaire ?
            <SubmissionDialog
                snackbar={snackbar}
                questionnaire={questionnaire}
                questions={questionsToUse}
                submission={submission}
                onCancel={() => {
                    setShowFillOut(false);
                }}
                onSave={(saved) => {
                    setShowFillOut(false);
                    onSaveSubmission(saved);
                }}
            /> : null}
    </div>
}

export default withRouter(QuestionnairesPage);
