import React from "react";
import {FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {objects, strings} from "@atttomyx/shared-utils";
import {TYPE_ADMIN, TYPE_INVENTOR, TYPE_INVESTOR} from "../../constants";

const UserTypeForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.type);

        onSync(modified);
    };

    return <div className="user-type-form">
        <div className="option">
            <FormLabel id="type">Type</FormLabel>
            <RadioGroup row={true} aria-labelledby="type"
                        onChange={(event) => onChange("type", event.target.value)}>
                <FormControlLabel
                    value={TYPE_ADMIN}
                    label={TYPE_ADMIN}
                    control={<Radio checked={data.type === TYPE_ADMIN} color="primary"/>}
                />
                <FormControlLabel
                    value={TYPE_INVESTOR}
                    label={TYPE_INVESTOR}
                    control={<Radio checked={data.type === TYPE_INVESTOR} color="primary"/>}
                />
                <FormControlLabel
                    value={TYPE_INVENTOR}
                    label={TYPE_INVENTOR}
                    control={<Radio checked={data.type === TYPE_INVENTOR} color="primary"/>}
                />
            </RadioGroup>
        </div>
    </div>
}

export default UserTypeForm;
