import axios from 'axios';

export const mineSubmissions = (cursor, limit, success, failure) => {
    findSubmissions("/api/v1/mySubmission/list", cursor, limit, success, failure);
};

export const listSubmissions = (cursor, limit, success, failure) => {
    findSubmissions("/api/v1/submission/list", cursor, limit, success, failure);
};

const findSubmissions = (endpoint, cursor, limit, success, failure) => {
    let url = endpoint + "?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(failure);
};

export const saveSubmission = (questionnaireId, questionsAndAnswers, success, failure) => {
    const url = "/api/v1/mySubmission/";

    axios.post(url, {
        questionnaireId: questionnaireId,
        questions: questionsAndAnswers,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const deleteSubmission = (submissionId, success, failure) => {
    const url = `/api/v1/mySubmission/${submissionId}/`;

    axios.delete(url)
    .then(response => {
        success(submissionId);
    })
    .catch(err => {
        failure(err);
    });
};
