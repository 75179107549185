import React, {useState} from 'react';
import {Card, CardActions, CardContent} from "@mui/material";
import {CardData, CardTitle, ImageDialog} from "@atttomyx/react-components";
import {cloudinary, datetime, objects, strings} from "@atttomyx/shared-utils";
import "./courseCard.css";

const getCount = (documentIdsByCourseId, courseId) => {
    const documentIds = objects.defaultIfNullOrUndefined(documentIdsByCourseId[courseId], []);

    return documentIds.length;
};

const CourseCard = (props) => {
    const { course, documentIdsByCourseId, filters, children } = props;
    const [showDialog, setShowDialog] = useState(false);

    return <Card className="course-card" raised={true}>
        <CardContent>
            <CardTitle>
                <img
                    className="course"
                    alt={course.name}
                    src={course.imageUrl ? cloudinary.trimAndShrink(course.imageUrl) : "https://placehold.co/100x100"}
                    onClick={() => setShowDialog(strings.isNotBlank(course.imageUrl))}
                />
            </CardTitle>
            <CardData
                filters={filters}
                data={course}
                keyToMetadata={{
                    name: {
                        title: "Name",
                    },
                    numFiles: {
                        title: "Files",
                        getter: (data) => getCount(documentIdsByCourseId, data.id),
                    },
                    status: {
                        title: "Status",
                        className: "status " + course.status,
                    },
                    created: {
                        title: "Created",
                        formatter: datetime.getFullDateAndTime,
                    },
                    updated: {
                        title: "Updated",
                        formatter: datetime.getFullDateAndTime,
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
        {showDialog ?
            <ImageDialog
                title={course.name}
                imageUrl={course.imageUrl}
                onCancel={() => setShowDialog(false)}
            /> : null}
    </Card>
}

export default CourseCard;
