import React from "react";
import {TextField} from "@mui/material";
import {forms, objects, strings} from "@atttomyx/shared-utils";
import {STATUSES} from "../../constants";
import {SelectField} from "@atttomyx/react-components";

const QuestionnaireForm = (props) => {
    const { data, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.title) && strings.isNotBlank(modified.status);

        onSync(modified);
    };

    return <div className="questionnaire-form">
        <div className="field">
            <TextField
                required={true}
                label="Title"
                type="text"
                value={forms.sanitizeValue(data.title)}
                onChange={(event) => onChange("title", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField
                label="Description"
                type="text"
                value={forms.sanitizeValue(data.description)}
                onChange={(event) => onChange("description", event.target.value)}
            />
        </div>
        <div className="field">
            <SelectField
                required={true}
                label="Status"
                value={forms.sanitizeValue(data.status)}
                onChange={(value) => onChange("status", value)}
                options={STATUSES}
            />
        </div>
    </div>
}

export default QuestionnaireForm;
