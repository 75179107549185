import {filters} from "@atttomyx/shared-constants";

export const COURSE_FILTERS = {
    name: {
        type: filters.TYPE_STRING,
    },
    status: {
        type: filters.TYPE_STRING,
    },
};

export const QUESTIONNAIRE_FILTERS = {
    title: {
        type: filters.TYPE_STRING,
    },
    description: {
        type: filters.TYPE_STRING,
    },
    status: {
        type: filters.TYPE_STRING,
    },
};

export const getUserFilters = (questions) => {
    const keyToMetadata = {
        firstName: {
            type: filters.TYPE_STRING,
        },
        lastName: {
            type: filters.TYPE_STRING,
        },
        type: {
            type: filters.TYPE_STRING,
            getter: (item) => item.settings.type,
        },
    };

    questions.forEach(question => {
        const questionId = question.id;

        keyToMetadata[questionId] = {
            type: filters.TYPE_STRING,
            label: question.ask,
            getter: (item) => item.questions[questionId],
        };
    });

    return keyToMetadata;
};
