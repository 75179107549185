import React, {useMemo} from "react";
import {SelectField} from "@atttomyx/react-components";
import {arrays, forms, objects} from "@atttomyx/shared-utils";

const AddDocumentForm = (props) => {
    const { courses, documents, data, onChange : onSync } = props;

    const course = useMemo(() => {
        return arrays.findEntity(courses, data.courseId);
    }, [courses, data.courseId]);

    const documentsToUse = useMemo(() => {
        const array = [];

        if (course) {
            documents
            .filter(document => arrays.contains(document.courseIds, course.id))
            .forEach((document) => arrays.addTo(array, document));
        }

        return array;
    }, [course, documents]);

    const courseIdToName = useMemo(() => {
        const idToField = {};

        courses.map(course => idToField[course.id] = course.name);

        return idToField;
    }, [courses]);

    const documentIdToName = useMemo(() => {
        const idToField = {};

        documentsToUse.map(document => idToField[document.id] = document.fileName);

        return idToField;
    }, [documentsToUse]);

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        if (field === "courseId") {
            modified.documentId = null;
        }

        modified.valid = modified.documentId;

        onSync(modified);
    };

    return <div className="add-document-form">
        <div className="field top-margin">
            <SelectField
                label="Course"
                value={forms.sanitizeValue(data.courseId)}
                onChange={(value) => onChange("courseId", value)}
                options={courseIdToName}
            />
        </div>
        <div className="field">
            <SelectField
                label="File"
                value={forms.sanitizeValue(data.documentId)}
                onChange={(value) => onChange("documentId", value)}
                options={documentIdToName}
            />
        </div>
    </div>
}

export default AddDocumentForm;
