import React, {useEffect, useMemo, useState} from "react";
import {withRouter} from "react-router-dom";
import {useFiltered} from "@atttomyx/shared-hooks";
import {CircularProgress, IconButton, Typography} from "@mui/material";
import {ContentCopy as CloneIcon, Delete as DeleteIcon, Edit as EditIcon,} from "@mui/icons-material";
import {Cards, ConfirmDeleteDialog, FiltersAccordion, FloatingAddButton} from "@atttomyx/react-components";
import {CourseCard, DocumentsCard} from "../cards";
import {AddCourseDialog} from "../dialogs";
import {CourseFilters} from "../filters";
import {courseService} from "../../services";
import {arrays, objects} from "@atttomyx/shared-utils";
import {confirm, router} from "@atttomyx/react-utils";
import {PAGE_COURSE} from "../../constants";

const CoursesPage = (props) => {
    const { history, snackbar, dimensions, user, courses, documents, filters,
        onSave, onCopy, onDelete } = props;
    const [ course, setCourse ] = useState(null);
    const [ cloning, setCloning ] = useState(null);
    const [ showAdd, setShowAdd ] = useState(false);
    const [ showDelete, setShowDelete ] = useState(false);
    const [ showFilters, setShowFilters ] = useState(false);
    const filtered = useFiltered(courses, filters);

    const documentsById = useMemo(() => {
        return arrays.getIdToEntity(documents);
    }, [documents]);

    const documentIdsByCourseId = useMemo(() => {
        const documentIds = {};

        documents.forEach(document => {
            const documentId = document.id;

            document.courseIds.forEach(courseId => {
                const array = objects.defaultIfNullOrUndefined(documentIds[courseId], []);

                arrays.addTo(array, documentId);
                documentIds[courseId] = array;
            });
        });

        return documentIds;
    }, [documents]);

    const Card = useMemo(() => {
        return user.roles.admin ? CourseCard : DocumentsCard;
    }, [user]);

    useEffect(() => {
        if (!course) {
            setShowAdd(false);
            setShowDelete(false);
        }
    }, [course]);

    useEffect(() => {
        if (cloning) {
            const success = (result) => {
                snackbar.setSuccess("Course copied");
                setCloning(null);
                onCopy(result);
            };

            const failure = (err) => {
                snackbar.setError(err);
                setCloning(null);
            };

            courseService.cloneCourse(cloning.id, success, failure);
        }
    }, [cloning]);

    return <div className="courses-page">
        <Typography variant="h5" paragraph={true}>
            Courses
        </Typography>
        <div className="field">
            <FiltersAccordion
                filters={filters}
                form={CourseFilters}
                open={showFilters}
                onOpen={() => setShowFilters(true)}
                onClose={() => setShowFilters(false)}
                formProps={{
                    user: user,
                }}
            />
        </div>
        <Cards
            items={filtered}
            paging={filters.paging}
            dimensions={dimensions}
            renderer={(course) =>
                <Card
                    key={course.id}
                    snackbar={snackbar}
                    course={course}
                    documentIdsByCourseId={documentIdsByCourseId}
                    documentsById={documentsById}
                    filters={filters}
                >
                    {user.roles.admin ?
                        <>
                            <IconButton color="secondary" title="Delete" className="delete"
                                        onClick={() => {
                                            setCourse(course);
                                            setShowDelete(true);
                                        }}>
                                <DeleteIcon/>
                            </IconButton>
                            {cloning && cloning.id === course.id ?
                                <CircularProgress size="18px"/> :
                                <IconButton color="primary" title="Copy"
                                            onClick={() => confirm.confirm(
                                                `Are you sure you want to make a copy of ${course.name}?`,
                                                () => setCloning(course))}>
                                    <CloneIcon/>
                                </IconButton>}
                            <IconButton color="primary" title="Edit"
                                        onClick={() => router.redirectTo(history, PAGE_COURSE + "/" + course.id)}>
                                <EditIcon/>
                            </IconButton>
                        </> : null}
                </Card>}
        />
        {user.roles.admin ?
            <FloatingAddButton
                title="Add course"
                position="higher"
                onClick={() => {
                    setShowAdd(true);
                }}
            /> : null}
        {showAdd ?
            <AddCourseDialog
                snackbar={snackbar}
                onCancel={() => {
                    setShowAdd(false);
                }}
                onSave={(saved) => {
                    setShowAdd(false);
                    onSave(saved);
                }}
            /> : null}
        {showDelete && course ?
            <ConfirmDeleteDialog
                snackbar={snackbar}
                title={course.name}
                onCancel={() => {
                    setShowDelete(false);
                    setCourse(null);
                }}
                onDelete={(courseId) => {
                    setShowDelete(false);
                    setCourse(null);
                    onDelete(courseId);
                }}
                deleter={(success, failure) => courseService.deleteCourse(course.id, success, failure)}
            /> : null}
    </div>
}

export default withRouter(CoursesPage);
