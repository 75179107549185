import {arrays, objects, users} from "@atttomyx/shared-utils";
import {themes} from "@atttomyx/shared-constants";
import {INVENTOR, INVESTOR, TYPE_ADMIN, TYPE_INVENTOR, TYPE_INVESTOR} from "../constants";

export const sanitizeUser = (user) => {
    user.roles = normalizeRoles(user.roles);
    user.settings = sanitizeSettings(user.settings);
};

export const sanitizeProfile = (profile) => {
    profile.settings = sanitizeSettings(profile.settings);
};

const sanitizeSettings = (settings) => {
    const sanitized = settings || {};

    sanitized.theme = objects.defaultIfNullOrUndefined(sanitized.theme, themes.LIGHT);
    sanitized.type = objects.defaultIfNullOrUndefined(sanitized.type, TYPE_ADMIN);

    return sanitized;
};

export const normalizeRoles = (roles) => {
    const sanitized = roles || [];
    const normalized = users.normalizeRoles(sanitized);

    normalized.investor = arrays.contains(sanitized, INVESTOR);
    normalized.inventor = arrays.contains(sanitized, INVENTOR);

    return normalized;
};

export const getRoles = (type) => {
    const roles = {
        user: true,
        admin: false,
    };

    if (type === TYPE_ADMIN) {
        roles.admin = true;
    }

    return roles;
};

export const getDecoratedRoles = (type) => {
    const roles = getRoles(type);

    roles.investor = type === TYPE_INVESTOR;
    roles.inventor = type === TYPE_INVENTOR;

    return roles;
};

export const getSettings = (settings, type) => {
    const decorated = objects.deepCopy(settings);

    decorated.type = type;

    return decorated;
};
