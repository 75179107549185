import React from "react";
import {Card, CardActions, CardContent, Divider, Typography} from "@mui/material";
import {UserAvatar} from "@atttomyx/react-components";
import {misc} from "@atttomyx/shared-constants";
import "./userCard.css";

const UserCard = (props) => {
    const { user, me, submission, children } = props;
    let n = 0;

    return <Card className="user-card" raised={true}>
        <CardContent>
            <UserAvatar user={user} mode="right"/>
            {me.roles.admin ?
                <Typography variant="caption" component="div" className="role">
                    ({(user.settings.type ? user.settings.type : misc.UNKNOWN).toLowerCase()})
                </Typography> : null}
            {submission ?
                <>
                    <Divider/>
                    {submission.questions.map(question => <div key={"question" + n++}>
                        <Typography variant="caption" component="div" className="ask">
                            {question.ask}
                        </Typography>
                        <Typography variant="caption" component="div" className="reply">
                            {question.reply}
                        </Typography>
                    </div>)}
                </> : null}
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default UserCard;
