import React, {useState} from "react";
import {CircularProgress, IconButton, TextField} from "@mui/material";
import {Delete as DeleteIcon, Edit as EditIcon} from "@mui/icons-material";
import {courseService} from "../../services";
import {cloudinary, forms, objects, strings} from "@atttomyx/shared-utils";
import {STATUSES} from "../../constants";
import "./courseForm.css";
import {SelectField} from "@atttomyx/react-components";

const CourseForm = (props) => {
    const {snackbar, courseId, data, onChange: onSync} = props;
    const [uploading, setUploading] = useState(false);

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;
        modified.valid = strings.isNotBlank(modified.name) && strings.isNotBlank(modified.status);

        onSync(modified);
    };

    const uploadImage = (event) => {
        const file = event.target.files[0];

        setUploading(true);

        const success = (imageUrl) => {
            setUploading(false);
            onChange("imageUrl", imageUrl);
        };

        const failure = (err) => {
            setUploading(false);
            snackbar.setError(err);
        };

        courseService.uploadImage(courseId, file, success, failure);
    };

    const removeImage = () => {
        onChange("imageUrl", null);
    };

    return <div className="course-form">
        {courseId ?
            <div>
                {uploading ? <CircularProgress size="40px"/> :
                    <>
                        <img
                            className="course"
                            alt={data.title}
                            src={data.imageUrl ? cloudinary.shrink(data.imageUrl) : "https://placehold.co/100x100"}
                        />
                        <div>
                            <IconButton
                                color="secondary" title="Delete image"
                                disabled={!data.imageUrl}
                                onClick={removeImage}
                            >
                                <DeleteIcon/>
                            </IconButton>
                            <input
                                hidden
                                accept="image/*"
                                id="course-file"
                                type="file"
                                onChange={uploadImage}
                            />
                            <label htmlFor="course-file">
                                <IconButton color="primary" title="Upload image" component="span">
                                    <EditIcon/>
                                </IconButton>
                            </label>
                        </div>
                    </>}
            </div> : null}
        <div className="field top-margin">
            <TextField
                required={true}
                label="Name"
                type="text"
                value={forms.sanitizeValue(data.name)}
                onChange={(event) => onChange("name", event.target.value)}
            />
        </div>
        <div className="field">
            <SelectField
                required={true}
                label="Status"
                value={forms.sanitizeValue(data.status)}
                onChange={(value) => onChange("status", value)}
                options={STATUSES}
            />
        </div>
    </div>
}

export default CourseForm;
