import React from "react";
import {TextField} from "@mui/material";
import {SelectField} from "@atttomyx/react-components";
import {forms, objects, strings} from "@atttomyx/shared-utils";
import {ANSWER_TYPE_BOOLEAN, ANSWER_TYPE_DATE, ANSWER_TYPE_DOUBLE, ANSWER_TYPE_INTEGER, YES_NO} from "../../constants";

const SubmissionForm = (props) => {
    const { data, questions, onChange : onSync } = props;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);

        modified[field] = value;

        let valid = false;
        questions.forEach(question => {
            valid = valid || strings.isNotBlank(modified[question.id]);
        });
        modified.valid = valid;

        onSync(modified);
    };

    return <div className="submission-form">
        {questions.map(question => <div key={question.id} className="field">
            {question.type === ANSWER_TYPE_BOOLEAN ?
                <SelectField
                    label={question.ask}
                    value={forms.sanitizeValue(data[question.id])}
                    onChange={(value) => onChange(question.id, value)}
                    options={YES_NO}
                /> : question.type === ANSWER_TYPE_DATE ?
                <TextField
                    label={question.ask}
                    type="date"
                    value={forms.sanitizeValue(data[question.id])}
                    onChange={(event) => onChange(question.id, event.target.value)}
                /> : question.type === ANSWER_TYPE_INTEGER || question.type === ANSWER_TYPE_DOUBLE ?
                <TextField
                    label={question.ask}
                    type="number"
                    value={forms.sanitizeValue(data[question.id])}
                    onChange={(event) => onChange(question.id, event.target.value)}
                    inputProps={{
                        step: question.type === ANSWER_TYPE_INTEGER ? 1 : undefined,
                    }}
                /> :
                <TextField
                    label={question.ask}
                    type="text"
                    value={forms.sanitizeValue(data[question.id])}
                    onChange={(event) => onChange(question.id, event.target.value)}
                />}
        </div>)}
    </div>
}

export default SubmissionForm;
