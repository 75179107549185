import React, {useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, Typography} from "@mui/material";
import {UserForm} from "@atttomyx/react-components";
import {UserTypeForm} from "../forms";
import {forms} from "@atttomyx/shared-utils";
import {userUtils} from "../../utils";
import {verbiage} from "@atttomyx/shared-constants";

const freshData = () => {
    return {
        imageUrl: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        valid: false,
    }
};

const freshPermissions = () => {
    return {
        type: null,
        valid: false,
    }
};

const AddUserPage = (props) => {
    const { snackbar, onSave, userService } = props;
    const [ data, setData ] = useState(freshData());
    const [ permissions, setPermissions ] = useState(freshPermissions());
    const [ saving, setSaving ] = useState(false);

    const resetForm = () => {
        setData(freshData());
        setPermissions(freshPermissions());
    }

    const submitForm = () => {
        setSaving(true);

        const type = permissions.type;
        const added = {
            imageUrl: data.imageUrl,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            roles: userUtils.getRoles(type),
            settings: userUtils.getSettings({}, type),
        };

        const success = (user) => {
            resetForm();
            setSaving(false);

            snackbar.setSuccess("User saved");
            onSave(user);
        };

        const failure = (err) => {
            setSaving(false);

            snackbar.setError(err);
        };

        userService.createUser(added, success, failure);
    }

    const valid = data.valid && permissions.valid;
    const modified = forms.differ(freshData(), data) || forms.differ(freshPermissions(), permissions);

    return saving ?
        <div className="add-user-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="add-user-page">
            <Typography variant="h5" paragraph={true}>
                Add User
            </Typography>
            <UserForm
                snackbar={snackbar}
                data={data}
                onChange={setData}
                showImage={false}
                showPhone={true}
                showBirthday={true}
            />
            <UserTypeForm
                data={permissions}
                onChange={setPermissions}
            />
            <div className="actions">
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default AddUserPage;
