import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {AppBar, Badge, BottomNavigation, BottomNavigationAction} from "@mui/material";
import {
    Folder as CoursesIcon,
    MoreHoriz as MoreIcon,
    Notifications as NotificationsIcon,
    People as UsersIcon,
    Quiz as QuestionnaireIcon,
} from "@mui/icons-material";
import {MoreMenu} from "../nav";
import {router} from "@atttomyx/react-utils";
import {arrays, strings} from "@atttomyx/shared-utils";
import {
    PAGE_COURSE,
    PAGE_COURSES,
    PAGE_NOTIFICATIONS,
    PAGE_QUESTIONNAIRE,
    PAGE_QUESTIONNAIRES,
    PAGE_USER,
    PAGE_USERS,
    PAGES_MAIN,
    PAGES_MORE,
} from "../../constants";
import "./footer.css";

const PAGE_MORE = "more";
const PAGE_OTHER = "other";

const Footer = (props) => {
    const { history, user, numNotifications, onLogout } = props;
    const [showMore, setShowMore] = useState(false);

    const path = strings.sanitizeStr(router.getCurrentPage(history));
    let page = PAGE_OTHER;

    if (arrays.contains(PAGES_MAIN, path)) {
        page = path;

    } else if (path === PAGE_USER || path.startsWith(PAGE_USER + "/")) {
        page = PAGE_USERS;

    } else if (path === PAGE_COURSE || path.startsWith(PAGE_COURSE + "/")) {
        page = PAGE_COURSES;

    } else if (path === PAGE_QUESTIONNAIRE || path.startsWith(PAGE_QUESTIONNAIRE + "/")) {
        page = PAGE_QUESTIONNAIRES;

    } else if (arrays.contains(PAGES_MORE, path)) {
        page = PAGE_MORE;
    }

    return <div className="footer">
        <AppBar className="bottom-nav" position="fixed" color="default">
            <BottomNavigation
                value={page}
                showLabels={true}
            >
                <BottomNavigationAction
                    value={PAGE_NOTIFICATIONS}
                    label="Notifications"
                    icon={
                        <Badge
                            color="error"
                            badgeContent={numNotifications}
                            invisible={numNotifications === 0}>
                            <NotificationsIcon/>
                        </Badge>}
                    onClick={() => router.redirectTo(history, PAGE_NOTIFICATIONS)}
                />
                {user.roles.admin || user.roles.inventor ?
                    <BottomNavigationAction
                        value={PAGE_COURSES}
                        label="Courses"
                        icon={<CoursesIcon/>}
                        onClick={() => router.redirectTo(history, PAGE_COURSES)}
                    /> : null}
                {user.roles.admin || user.roles.inventor ?
                    <BottomNavigationAction
                        value={PAGE_QUESTIONNAIRES}
                        label="Questionnaires"
                        icon={<QuestionnaireIcon/>}
                        onClick={() => router.redirectTo(history, PAGE_QUESTIONNAIRES)}
                    /> : null}
                {user.roles.admin || user.roles.investor ?
                    <BottomNavigationAction
                        value={PAGE_USERS}
                        label={user.roles.admin ? "Users" : "Inventors"}
                        icon={<UsersIcon/>}
                        onClick={() => router.redirectTo(history, PAGE_USERS)}
                    /> : null}
                <BottomNavigationAction
                    value={PAGE_MORE}
                    label="More"
                    icon={<MoreIcon/>}
                    component="div"
                    onClick={() => setShowMore(true)}
                />
            </BottomNavigation>
        </AppBar>
        {showMore ?
            <MoreMenu
                onCancel={() => setShowMore(false)}
                onLogout={onLogout}
            /> : null}
    </div>
}

export default withRouter(Footer);
