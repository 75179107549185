import axios from 'axios';

export const listQuestions = (cursor, limit, success, failure) => {
    let url = "/api/v1/question/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const createQuestion = (question, success, failure) => {
    const url = "/api/v1/question/";

    axios.post(url, {
        questionnaireId: question.questionnaireId,
        ask: question.ask,
        type: question.type,
        order: question.order,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const saveQuestion = (questionId, question, success, failure) => {
    const url = `/api/v1/question/${questionId}/`;

    axios.put(url, {
        questionnaireId: question.questionnaireId,
        ask: question.ask,
        type: question.type,
        order: question.order,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const deleteQuestion = (questionId, success, failure) => {
    const url = `/api/v1/question/${questionId}/`;

    axios.delete(url)
    .then(response => {
        success(questionId);
    })
    .catch(err => {
        failure(err);
    });
};
