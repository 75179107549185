import React from 'react';
import {Card, CardActions, CardContent, Divider, Typography} from "@mui/material";
import {CardTitle} from "@atttomyx/react-components";
import "./submissionCard.css";

const SubmissionCard = (props) => {
    const {questionnaire, submission, children} = props;
    let n = 0;

    return <Card className="submission-card" raised={true}>
        <CardContent>
            <CardTitle>
                {questionnaire.title}
            </CardTitle>
            {submission ?
                <>
                    <Divider/>
                    {submission.questions.map(question => <div key={"question" + n++}>
                        <Typography variant="caption" component="div" className="ask">
                            {question.ask}
                        </Typography>
                        <Typography variant="caption" component="div" className="reply">
                            {question.reply}
                        </Typography>
                    </div>)}
                </> : questionnaire.description ?
                <Typography variant="caption">
                    {questionnaire.description}
                </Typography> : null}
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default SubmissionCard;
