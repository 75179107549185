import React, {useEffect, useMemo, useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, Divider, IconButton} from "@mui/material";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    NavigateBefore as PrevIcon,
    NavigateNext as NextIcon
} from "@mui/icons-material";
import {usePaging} from "@atttomyx/shared-hooks";
import {Cards, ConfirmDeleteDialog, FloatingAddButton, VerticalDivider} from "@atttomyx/react-components";
import {QuestionCard} from "../cards";
import {QuestionnaireForm} from "../forms";
import {AddQuestionDialog, EditQuestionDialog} from "../dialogs";
import {questionnaireService, questionService} from "../../services";
import {arrays, forms, strings} from "@atttomyx/shared-utils";
import {verbiage} from "@atttomyx/shared-constants";
import "./editQuestionnairePage.css";

const toData = (questionnaire) => {
    return {
        title: questionnaire.title,
        description: questionnaire.description,
        status: questionnaire.status,
        valid: strings.isNotBlank(questionnaire.title) && strings.isNotBlank(questionnaire.status),
    }
};

const EditQuestionnairePage = (props) => {
    const {
        snackbar, dimensions, match, questionnaires, questions, filters,
        onSaveQuestionnaire, onSaveQuestion, onDeleteQuestion, onPrev, onNext
    } = props;
    const [data, setData] = useState(toData({}));
    const [questionnaire, setQuestionnaire] = useState({});
    const [question, setQuestion] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [saving, setSaving] = useState(false);
    const [prevId, setPrevId] = useState(null);
    const [nextId, setNextId] = useState(null);
    const paging = usePaging();

    const questionsToUse = useMemo(() => {
        const questionnaireId = questionnaire.id;

        return questions.filter(question => question.questionnaireId === questionnaireId);
    }, [questionnaire, questions]);

    const nextOrder = useMemo(() => {
        let next = questionsToUse.length;

        questionsToUse.forEach(question => {
            if (question.order >= next) {
                next = question.order + 1;
            }
        });

        return next;
    }, [questionsToUse]);

    const loadQuestionnaire = (questionnaireId) => {
        const previousId = questionnaire.id;
        const filtered = filters.filter(questionnaires);
        const found = arrays.findEntity(filtered, questionnaireId) || {};
        const ids = arrays.getPrevNextIds(filtered, found);

        setQuestionnaire(found);
        setPrevId(ids.prevId);
        setNextId(ids.nextId);

        if (previousId && previousId !== questionnaireId && found.id === questionnaireId) {
            snackbar.setInfo("Loaded " + found.title);
        }
    };

    const redirectToPrev = () => {
        onPrev(prevId);
        loadQuestionnaire(prevId);
    }

    const redirectToNext = () => {
        onNext(nextId);
        loadQuestionnaire(nextId);
    }

    useEffect(() => {
        const questionnaireId = match.params.id;

        loadQuestionnaire(questionnaireId);
    }, []);

    useEffect(() => {
        setData(toData(questionnaire));
    }, [questionnaire]);

    const resetForm = () => {
        setData(toData(questionnaire));
    };

    const submitForm = () => {
        setSaving(true);

        const modified = {
            title: data.title,
            description: data.description,
            status: data.status,
        };

        const success = (questionnaire) => {
            setQuestionnaire(questionnaire);
            setSaving(false);

            snackbar.setSuccess("Questionnaire saved");
            onSaveQuestionnaire(questionnaire);
        };

        const failure = (err) => {
            setSaving(false);
            snackbar.setError(err);
        };

        questionnaireService.saveQuestionnaire(questionnaire.id, modified, success, failure);
    };

    const valid = data.valid;
    const modified = forms.differ(toData(questionnaire), data);

    return saving ?
        <div className="edit-questionnaire-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="edit-questionnaire-page">
            <QuestionnaireForm
                data={data}
                onChange={setData}
            />
            <div className="actions">
                <IconButton color="secondary" title="Previous questionnaire"
                            disabled={!prevId}
                            onClick={redirectToPrev}>
                    <PrevIcon/>
                </IconButton>
                <IconButton color="secondary" title="Next questionnaire"
                            disabled={!nextId}
                            onClick={redirectToNext}>
                    <NextIcon/>
                </IconButton>
                <VerticalDivider/>
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Divider/>
            <Cards
                label="image"
                items={questionsToUse}
                paging={paging}
                dimensions={dimensions}
                renderer={(question) => <QuestionCard key={question.id} question={question}>
                    <IconButton
                        color="secondary" title="Delete" className="delete"
                        onClick={() => {
                            setQuestion(question);
                            setShowDelete(true);
                        }}
                    >
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton
                        color="primary" title="Edit"
                        onClick={() => {
                            setQuestion(question);
                            setShowEdit(true);
                        }}
                    >
                        <EditIcon/>
                    </IconButton>
                </QuestionCard>}
            />
            <FloatingAddButton
                title="Add question"
                position="higher"
                onClick={() => {
                    setShowAdd(true);
                }}
            />
            {showAdd ?
                <AddQuestionDialog
                    snackbar={snackbar}
                    questionnaireId={questionnaire.id}
                    order={nextOrder}
                    onCancel={() => {
                        setShowAdd(false);
                    }}
                    onSave={(saved) => {
                        setShowAdd(false);
                        onSaveQuestion(saved);
                    }}
                /> : null}
            {showEdit && question ?
                <EditQuestionDialog
                    snackbar={snackbar}
                    question={question}
                    onCancel={() => {
                        setShowEdit(false);
                    }}
                    onSave={(saved) => {
                        setShowEdit(false);
                        onSaveQuestion(saved);
                    }}
                /> : null}
            {showDelete && question ? <ConfirmDeleteDialog
                snackbar={snackbar}
                title={question.ask}
                onCancel={() => {
                    setShowDelete(false);
                    setQuestion(null);
                }}
                onDelete={(questionId) => {
                    setShowDelete(false);
                    setQuestion(null);
                    onDeleteQuestion(questionId);
                }}
                deleter={(success, failure) => questionService.deleteQuestion(question.id, success, failure)}
            /> : null}
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default EditQuestionnairePage;
