import React from 'react';
import {Card, CardActions, CardContent} from "@mui/material";
import {CardData, CardTitle} from "@atttomyx/react-components";
import {datetime, numbers} from "@atttomyx/shared-utils";

const DocumentCard = (props) => {
    const { document, filters, children } = props;

    return <Card className="document-card" raised={true}>
        <CardContent>
            <CardTitle>
                {document.fileName}
            </CardTitle>
            <CardData
                filters={filters}
                data={document}
                keyToMetadata={{
                    contentType: {
                        title: "Type",
                    },
                    size: {
                        title: "Size",
                        formatter: numbers.abbreviateNumber,
                    },
                    created: {
                        title: "Created",
                        formatter: datetime.getFullDateAndTime,
                    },
                    updated: {
                        title: "Updated",
                        formatter: datetime.getFullDateAndTime,
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default DocumentCard;
