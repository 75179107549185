import React, {useEffect, useState} from "react";
import {Prompt} from "react-router-dom";
import {Button, CircularProgress, IconButton, Typography} from "@mui/material";
import {NavigateBefore as PrevIcon, NavigateNext as NextIcon} from "@mui/icons-material";
import {UserForm, VerticalDivider} from "@atttomyx/react-components";
import {UserTypeForm} from "../forms";
import {arrays, forms, objects, strings} from "@atttomyx/shared-utils";
import {userUtils} from "../../utils";
import {verbiage} from "@atttomyx/shared-constants";
import "./editUserPage.css";

const isValid = (user) => strings.isNotBlank(user.id);

const toData = (user) => {
    return {
        imageUrl: user.imageUrl,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        valid: isValid(user),
    }
};

const toPermissions = (user) => {
    const settings = objects.defaultIfNullOrUndefined(user.settings, {});
    const type = settings.type;

    return {
        type: type,
        valid: strings.isNotBlank(type),
    }
};

const toSettings = (user) => {
    const data = user.settings ? objects.deepCopy(user.settings) : {};

    data.valid = isValid(user);

    return data;
};

const EditUserPage = (props) => {
    const { snackbar, match, user : me, users, filters, onSave, onPrev, onNext, userService, showImage,
        settingsForm: SettingsForm, settingsProps,
        settingsCards,  // if true, SettingsForm is expected to internally put fields in "card" divs
    } = props;
    const [ user, setUser ] = useState({})
    const [ data, setData ] = useState(toData({}));
    const [ permissions, setPermissions ] = useState(toPermissions({}));
    const [ settings, setSettings ] = useState(toSettings({}));
    const [ saving, setSaving ] = useState(false);
    const [ prevId, setPrevId ] = useState(null);
    const [ nextId, setNextId ] = useState(null);

    const loadUser = (userId) => {
        const filtered = filters.filter(users);
        const user = arrays.findEntity(filtered, userId) || {};
        const ids = arrays.getPrevNextIds(filtered, user, me.id);

        setUser(user);
        setPrevId(ids.prevId);
        setNextId(ids.nextId);
    };

    const redirectToPrev = () => {
        onPrev(prevId);
        loadUser(prevId);
    };

    const redirectToNext = () => {
        onNext(nextId);
        loadUser(nextId);
    };

    useEffect(() => {
        const userId = match.params.id;

        loadUser(userId);
    }, []);

    useEffect(() => {
        setData(toData(user));
        setPermissions(toPermissions(user));
        setSettings(toSettings(user));
    }, [user]);

    const resetForm = () => {
        setData(toData(user));
        setPermissions(toPermissions(user));
        setSettings(toSettings(user));
    };

    const cleanForm = () => {
        setData(forms.cleanData(data));
        setPermissions(forms.cleanData(permissions));
        setSettings(forms.cleanData(settings));
    };

    const submitForm = () => {
        setSaving(true);

        const type = permissions.type;
        const modified = {
            imageUrl: data.imageUrl,
            firstName: data.firstName,
            lastName: data.lastName,
            alias: user.alias,
            email: data.email,
            phone: data.phone,
            birthday: data.birthday,
            roles: userUtils.getRoles(type),
            settings: userUtils.getSettings(forms.rawData(settings), type),
        };

        const success = (user) => {
            cleanForm();
            setSaving(false);

            snackbar.setSuccess("User saved");
            setUser(user);
            onSave(user);
        };

        const failure = (err) => {
            setSaving(false);

            snackbar.setError(err);
        };

        userService.saveUser(user.id, modified, success, failure);
    };

    const valid = data.valid && permissions.valid && settings.valid;
    const modified = forms.differ(toData(user), data) || forms.differ(toPermissions(user), permissions)
        || forms.differ(toSettings(user), settings);

    return saving ?
        <div className="edit-user-page">
            <CircularProgress size="80px"/>
        </div> :
        <div className="edit-user-page">
            {!showImage ? <Typography variant="h5" paragraph={true}>
                Edit User
            </Typography> : null}
            <div className="cards">
                <div className="card">
                    <UserForm
                        snackbar={snackbar}
                        userId={user.id}
                        data={data}
                        onChange={setData}
                        showImage={showImage}
                        showPhone={true}
                        showBirthday={true}
                        imageService={userService}
                    />
                    <UserTypeForm
                        data={permissions}
                        onChange={setPermissions}
                    />
                </div>
                {SettingsForm ? settingsCards ?
                    <SettingsForm
                        data={settings}
                        onChange={setSettings}
                        settingsProps={settingsProps}
                    /> :
                    <div className="card">
                        <SettingsForm
                            data={settings}
                            onChange={setSettings}
                            settingsProps={settingsProps}
                        />
                    </div> : null}
            </div>
            <div className="actions">
                <IconButton color="secondary" title="Previous user"
                            disabled={!prevId}
                            onClick={redirectToPrev}>
                    <PrevIcon/>
                </IconButton>
                <IconButton color="secondary" title="Next user"
                            disabled={!nextId}
                            onClick={redirectToNext}>
                    <NextIcon/>
                </IconButton>
                <VerticalDivider/>
                <Button color="secondary" variant="text"
                        disabled={!modified}
                        onClick={resetForm}>
                    Undo
                </Button>
                <Button color="primary" size="large"
                        disabled={!valid || !modified || !user.id}
                        onClick={submitForm}>
                    Save
                </Button>
            </div>
            <Prompt when={modified} message={verbiage.UNSAVED_CHANGES}/>
        </div>
}

export default EditUserPage;
