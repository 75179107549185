import axios from "axios";
import {arrays} from "@atttomyx/shared-utils";

export const listDocuments = (cursor, limit, success, failure) => {
    let url = "/api/v1/document/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const uploadFile = (courseId, file, success, failure) => {
    const url = `/api/v1/document/upload`;

    const data = new FormData();
    data.append("file", file);
    data.append("courseId", courseId);

    axios.post(url, data, {
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const downloadFile = (documentId, success, failure) => {
    const url = `/api/v1/document/download/${documentId}/`;

    axios.get(url, {
        responseType: "arraybuffer",
    })
    .then(response => {
        const bytes = response.data;

        success(bytes);
    })
    .catch(err => {
        failure(err);
    });
};

export const addToCourse = (courseId, document, success, failure) => {
    const modified = arrays.copy(document.courseIds);

    arrays.addTo(modified, courseId);
    saveCourses(document.id, modified, success, failure);
};

export const removeFromCourse = (courseId, document, success, failure) => {
    const modified = arrays.copy(document.courseIds);

    arrays.removeFrom(modified, courseId);
    saveCourses(document.id, modified, success, failure);
};

const saveCourses = (documentId, courseIds, success, failure) => {
    const url = `/api/v1/document/${documentId}/`;

    axios.put(url, {
        courseIds: courseIds,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};
