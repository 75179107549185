import React from 'react';
import {Card, CardActions, CardContent, Typography} from "@mui/material";
import {CardData, CardTitle} from "@atttomyx/react-components";
import {datetime} from "@atttomyx/shared-utils";

const QuestionnaireCard = (props) => {
    const { questionnaire, numQuestions = 0, filters, children } = props;

    return <Card className="questionnaire-card" raised={true}>
        <CardContent>
            <CardTitle>
                <Typography variant="body1">
                    {questionnaire.title}
                </Typography>
                {questionnaire.description ?
                    <Typography variant="caption">
                        {questionnaire.description}
                    </Typography> : null}
            </CardTitle>
            <CardData
                filters={filters}
                data={questionnaire}
                keyToMetadata={{
                    numQuestions: {
                        title: "Questions",
                        getter: (data) => numQuestions,
                    },
                    status: {
                        title: "Status",
                        className: "status " + questionnaire.status,
                    },
                    created: {
                        title: "Created",
                        formatter: datetime.getFullDateAndTime,
                    },
                    updated: {
                        title: "Updated",
                        formatter: datetime.getFullDateAndTime,
                    },
                }}
            />
        </CardContent>
        <CardActions disableSpacing={true}>
            {children}
        </CardActions>
    </Card>
}

export default QuestionnaireCard;
