import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent} from "@mui/material";
import {ClosableDialogTitle} from "@atttomyx/react-components";
import {QuestionForm} from "../forms";
import {questionService} from "../../services";
import {forms, strings} from "@atttomyx/shared-utils";

const toData = (question) => {
    return {
        ask: question.ask,
        type: question.type,
        order: question.order,
        valid: strings.isNotBlank(question.ask) && strings.isNotBlank(question.type) && question.order >= 0,
    }
};

const EditQuestionDialog = (props) => {
    const { snackbar, question, onCancel, onSave } = props;
    const [ data, setData ] = useState(toData(question));
    const [ saving, setSaving ] = useState(false);

    const submitForm = () => {
        setSaving(true);

        const success = (saved) => {
            snackbar.setSuccess(saved.ask + " saved");
            setSaving(false);
            onSave(saved);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setSaving(false);
        };

        const modified = {
            questionnaireId: question.questionnaireId,
            ask: data.ask,
            type: data.type,
            order: data.order,
        };

        questionService.saveQuestion(question.id, modified, success, failure);
    }

    return <Dialog
        open={true}
        aria-labelledby="edit-question-dialog"
    >
        <ClosableDialogTitle onClose={onCancel}>
            Edit Question
        </ClosableDialogTitle>
        <DialogContent className="edit-question-dialog">
            {saving ?
                <CircularProgress size="40px"/> :
                <QuestionForm
                    data={data}
                    onChange={setData}
                />}
        </DialogContent>
        {!saving ? <DialogActions>
            <Button color="primary"
                    disabled={!data.valid || !forms.differ(toData(question), data)}
                    onClick={submitForm}>
                Save
            </Button>
        </DialogActions> : null}
    </Dialog>
}

export default EditQuestionDialog;
