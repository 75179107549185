import React, {useMemo, useState} from 'react';
import fileDownload from "js-file-download";
import {CardTitle, ImageDialog} from "@atttomyx/react-components";
import {
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {Download as DownloadIcon} from "@mui/icons-material";
import {documentService} from "../../services";
import {arrays, cloudinary, datetime, numbers, objects, strings} from "@atttomyx/shared-utils";
import "./documentsCard.css";

const DocumentsCard = (props) => {
    const { snackbar, course, documentIdsByCourseId, documentsById } = props;
    const [ downloading, setDownloading ] = useState(null);
    const [showDialog, setShowDialog] = useState(false);

    const documents = useMemo(() => {
        const documentIds = objects.defaultIfNullOrUndefined(documentIdsByCourseId[course.id], []);
        const array = [];

        documentIds.forEach(documentId => {
            const document = documentsById[documentId];

            if (document) {
                arrays.addTo(array, document);
            }
        });

        return array;
    }, [course, documentIdsByCourseId, documentsById]);

    const download = (document) => {
        setDownloading(document.id);

        const success = (bytes) => {
            fileDownload(bytes, document.fileName);
            setDownloading(null);
        };

        const failure = (err) => {
            snackbar.setError(err);
            setDownloading(null);
        };

        documentService.downloadFile(document.id, success, failure);
    };

    return <Card className="documents-card" raised={true}>
        <CardContent>
            <CardTitle>
                <Typography variant="subtitle2">
                    {course.name}
                </Typography>
                <img
                    className="course"
                    alt={course.name}
                    src={course.imageUrl ? cloudinary.trimAndShrink(course.imageUrl) : "https://placehold.co/100x100"}
                    onClick={() => setShowDialog(strings.isNotBlank(course.imageUrl))}
                />
            </CardTitle>
            <Table size="small" cellPadding={0} cellSpacing={0} className="striped">
                <TableHead>
                    <TableRow>
                        <TableCell className="download"/>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            Size
                        </TableCell>
                        <TableCell>
                            Updated
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents.map(document =>
                        <TableRow key={document.id}>
                            <TableCell>
                                {downloading === document.id ?
                                    <CircularProgress size="24px"/> :
                                    <IconButton color="primary" title={"Download " + document.fileName}
                                                onClick={() => download(document)}>
                                        <DownloadIcon/>
                                    </IconButton>}
                            </TableCell>
                            <TableCell className="file">
                                {document.fileName}
                            </TableCell>
                            <TableCell>
                                {numbers.abbreviateNumber(document.size)}
                            </TableCell>
                            <TableCell className="timestamp">
                                {datetime.getPrettyDateAndTime(document.updated)}
                            </TableCell>
                        </TableRow>)}
                </TableBody>
            </Table>
        </CardContent>
        {showDialog ?
            <ImageDialog
                title={course.name}
                imageUrl={course.imageUrl}
                onCancel={() => setShowDialog(false)}
            /> : null}
    </Card>
}

export default DocumentsCard;
