import React from "react";
import {TextField} from "@mui/material";
import {SelectField} from "@atttomyx/react-components";
import {forms, objects} from "@atttomyx/shared-utils";
import {STATUSES} from "../../constants";

const QuestionnaireFilters = (props) => {
    const { data, formProps, onChange : onSync } = props;
    const { user } = formProps;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        onSync(modified);
    }

    return <div className="questionnaire-filters">
        <div className="field">
            <TextField
                label="Title"
                type="text"
                value={forms.sanitizeValue(data.title)}
                onChange={(event) => onChange("title", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField
                label="Description"
                type="text"
                value={forms.sanitizeValue(data.description)}
                onChange={(event) => onChange("description", event.target.value)}
            />
        </div>
        {user.roles.admin ?
            <div className="field">
                <SelectField
                    label="Status"
                    value={forms.sanitizeValue(data.status)}
                    onChange={(value) => onChange("status", value)}
                    options={STATUSES}
                />
            </div> : null}
    </div>
}

export default QuestionnaireFilters;
