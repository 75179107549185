import axios from "axios";
import {CLOUDINARY_ID} from "../constants";

export const listCourses = (cursor, limit, success, failure) => {
    let url = "/api/v1/course/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const createCourse = (course, success, failure) => {
    const url = `/api/v1/course/`;

    axios.post(url, {
        name: course.name,
        imageUrl: course.imageUrl,
        status: course.status,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const updateCourse = (courseId, course, success, failure) => {
    const url = `/api/v1/course/${courseId}/`;

    axios.put(url, {
        name: course.name,
        imageUrl: course.imageUrl,
        status: course.status,
    })
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const cloneCourse = (courseId, success, failure) => {
    const url = `/api/v1/course/${courseId}/`;

    axios.post(url)
        .then(response => {
            const json = response.data;

            success(json);
        })
        .catch(err => {
            failure(err);
        });
};

export const deleteCourse = (courseId, success, failure) => {
    const url = `/api/v1/course/${courseId}/`;

    axios.delete(url)
        .then(response => {
            success(courseId);
        })
        .catch(err => {
            failure(err);
        });
};

export const uploadImage = (courseId, image, success, failure) => {
    const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_ID}/upload`;

    const data = new FormData();
    data.append("upload_preset", "course_image");
    data.append("file", image);
    data.append("tags", courseId);

    // need to skip the request interceptors (don't want to include the JWT)
    const instance = axios.create();

    instance.post(url, data, {
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
    })
    .then(response => {
        const json = response.data;

        success(json.secure_url);
    })
    .catch(err => {
        failure(err);
    });
};
