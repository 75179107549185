import {notifications} from "@atttomyx/shared-constants";

export const APP_NAME = "Courses";
export const APP_TAG_LINE = "Courses for inventors";

// export const API_BASE_URL = "http://localhost:8080";
export const API_BASE_URL = "https://courses-app-lw4r26jkhq-uc.a.run.app";
export const WEB_BASE_URL = "https://courses.milesoft.app";

export const PATH_DEEP_LINK = "/deep-link";

export const CLOUDINARY_ID = "cuteandfuzzy";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyA9Dj9SL_KXAQw4DfB-66XmJvyAUytOMdo",
    authDomain: "cf-notifier.firebaseapp.com",
    projectId: "cf-notifier",
    storageBucket: "cf-notifier.appspot.com",
    messagingSenderId: "741330969895",
    appId: "1:741330969895:web:795f316c4aca845967f502",
    measurementId: "G-7YK8FMDCTM"
};

export const NAMESPACE = "courses";
export const KEY_VERSION = "version";
export const KEY_AUTH_TOKEN = "auth_token";
export const KEY_TEMPORARY_PASSWORD = "temp_code";

export const OBSERVER_APP = "app";

export const TYPE_ADMIN = "Admin";
export const TYPE_INVESTOR = "Investor";
export const TYPE_INVENTOR = "Inventor";

export const INVESTOR = "ROLE_INVESTOR";
export const INVENTOR = "ROLE_INVENTOR";

export const TYPES = [
    TYPE_ADMIN,
    TYPE_INVESTOR,
    TYPE_INVENTOR,
];

export const STATUS_ACTIVE = "Active";
export const STATUS_DRAFT = "Draft";

export const STATUSES = [
    STATUS_ACTIVE,
    STATUS_DRAFT,
];

export const ANSWER_TYPE_STRING = "String";
export const ANSWER_TYPE_INTEGER = "Integer";
export const ANSWER_TYPE_DOUBLE = "Double";
export const ANSWER_TYPE_BOOLEAN = "Boolean";
export const ANSWER_TYPE_DATE = "Date";

export const ANSWER_TYPES = [
    ANSWER_TYPE_STRING,
    ANSWER_TYPE_INTEGER,
    ANSWER_TYPE_DOUBLE,
    ANSWER_TYPE_BOOLEAN,
    ANSWER_TYPE_DATE,
];

export const YES_NO = [
    "Yes",
    "No",
];

export const NOTIFICATION_TYPES = {
    account_joined: {
        admin: true,
        short: "New User",
        long: "A new user joins the account",
        delivery: notifications.DELIVERY_APP,
    },
};

export const TOPICS = {};

export const PAGE_LOGIN = "/login";
export const PAGE_RECOVERY = "/recovery";
export const PAGE_WELCOME = "/welcome";

export const PAGE_PROFILE = "/profile";
export const PAGE_OPTIONS = "/options";
export const PAGE_PASSWORD = "/password";

export const PAGE_ABOUT = "/about";
export const PAGE_NOTIFICATIONS = "/notifications";

export const PAGE_USERS = "/users";
export const PAGE_USER = "/user";

export const PAGE_COURSES = "/courses";
export const PAGE_COURSE = "/course";

export const PAGE_QUESTIONNAIRES = "/questionnaires";
export const PAGE_QUESTIONNAIRE = "/questionnaire";

export const PAGE_HOME = PAGE_NOTIFICATIONS;

export const PAGES_MAIN = [
    PAGE_NOTIFICATIONS,
    PAGE_USERS,
    PAGE_USER,
    PAGE_COURSES,
    PAGE_COURSE,
    PAGE_QUESTIONNAIRES,
    PAGE_QUESTIONNAIRE,
];

export const PAGES_MORE = [
    PAGE_PROFILE,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_ABOUT,
];

export const PRIVATE_PAGES_EXACT = [].concat(PAGES_MAIN).concat(PAGES_MORE);

export const PRIVATE_PAGES_STARTS_WITH = [
    PAGE_USER,
];

export const PUBLIC_PAGES_EXACT = [
    PAGE_LOGIN,
    PAGE_RECOVERY,
];

export const PUBLIC_PAGES_STARTS_WITH = [];
