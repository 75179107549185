import axios from 'axios';

export const listQuestionnaires = (cursor, limit, success, failure) => {
    let url = "/api/v1/questionnaire/list?limit=" + limit;

    if (cursor) {
        url += "&cursor=" + cursor;
    }

    axios.get(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const createQuestionnaire = (questionnaire, success, failure) => {
    const url = "/api/v1/questionnaire/";

    axios.post(url, {
        title: questionnaire.title,
        description: questionnaire.description,
        status: questionnaire.status,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const saveQuestionnaire = (questionnaireId, questionnaire, success, failure) => {
    const url = `/api/v1/questionnaire/${questionnaireId}/`;

    axios.put(url, {
        title: questionnaire.title,
        description: questionnaire.description,
        status: questionnaire.status,
    })
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const cloneQuestionnaire = (questionnaireId, success, failure) => {
    const url = `/api/v1/questionnaire/${questionnaireId}/`;

    axios.post(url)
    .then(response => {
        const json = response.data;

        success(json);
    })
    .catch(err => {
        failure(err);
    });
};

export const deleteQuestionnaire = (questionnaireId, success, failure) => {
    const url = `/api/v1/questionnaire/${questionnaireId}/`;

    axios.delete(url)
    .then(response => {
        success(questionnaireId);
    })
    .catch(err => {
        failure(err);
    });
};
