import React from "react";
import {Divider, TextField} from "@mui/material";
import {SelectField} from "@atttomyx/react-components";
import {forms, objects} from "@atttomyx/shared-utils";
import {TYPES} from "../../constants";

const UserFilters = (props) => {
    const { data, formProps, onChange : onSync } = props;
    const { user, questions } = formProps;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        onSync(modified);
    }

    return <div className="user-filters">
        <div className="field">
            <TextField
                label="First Name"
                type="text"
                value={forms.sanitizeValue(data.firstName)}
                onChange={(event) => onChange("firstName", event.target.value)}
            />
        </div>
        <div className="field">
            <TextField
                label="Last Name"
                type="text"
                value={forms.sanitizeValue(data.lastName)}
                onChange={(event) => onChange("lastName", event.target.value)}
            />
        </div>
        {user.roles.admin ?
            <div className="field">
                <SelectField
                    label="Type"
                    value={forms.sanitizeValue(data.type)}
                    onChange={(value) => onChange("type", value)}
                    options={TYPES}
                />
            </div> : null}
        {questions.length > 0 ?
            <>
                <Divider/>
                {questions.map(question => <div className="field" key={question.id}>
                    <TextField
                        label={question.ask}
                        type="text"
                        value={forms.sanitizeValue(data[question.id])}
                        onChange={(event) => onChange(question.id, event.target.value)}
                        />
                    </div>)}
                </> : null}
    </div>
}

export default UserFilters;
