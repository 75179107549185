import React from "react";
import {TextField} from "@mui/material";
import {forms, objects} from "@atttomyx/shared-utils";
import {STATUSES} from "../../constants";
import {SelectField} from "@atttomyx/react-components";

const CourseFilters = (props) => {
    const { data, formProps, onChange : onSync } = props;
    const { user } = formProps;

    const onChange = (field, value) => {
        const modified = objects.deepCopy(data);
        modified[field] = value;

        onSync(modified);
    }

    return <div className="course-filters">
        <div className="field">
            <TextField
                label="Name"
                type="text"
                value={forms.sanitizeValue(data.name)}
                onChange={(event) => onChange("name", event.target.value)}
            />
        </div>
        {user.roles.admin ?
            <div className="field">
                <SelectField
                    label="Status"
                    value={forms.sanitizeValue(data.status)}
                    onChange={(value) => onChange("status", value)}
                    options={STATUSES}
                />
            </div> : null}
    </div>
}

export default CourseFilters;
