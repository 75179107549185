import React, {useMemo, useState} from "react";
import {isMobile, isMobileOnly} from "react-device-detect";
import {IconButton} from "@mui/material";
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Email as EmailIcon,
    Phone as CallIcon,
    Sms as SmsIcon,
} from "@mui/icons-material";
import {useFiltered} from "@atttomyx/shared-hooks";
import {Cards, ConfirmDeleteDialog, FiltersAccordion, FloatingAddButton, SelectField} from "@atttomyx/react-components";
import {UserCard} from "../cards";
import {UserFilters} from "../filters";
import {arrays, forms, strings, users as userUtils} from "@atttomyx/shared-utils";
import {communication} from "@atttomyx/react-utils";
import {STATUS_ACTIVE} from "../../constants";
import "./usersPage.css";

const toComposite = (questionnaireId, userId) => `${questionnaireId}:${userId}`;

const UsersPage = (props) => {
    const { dimensions, snackbar, user: me, users, onDelete, onGotoProfile, onGotoUser, onGotoAdd,
        userService, filters, questionnaires, questions, submissions, questionnaireRenderer} = props;
    const [ user, setUser ] = useState(null);
    const [ showFilters, setShowFilters ] = useState(false);
    const filtered = useFiltered(users, filters, userUtils.sortByName);

    const idToQuestionnaire = useMemo(() => {
        return arrays.getIdToEntity(questionnaires);
    }, [questionnaires]);

    const idToTitle = useMemo(() => {
        const map = {};

        questionnaires
        .filter(questionnaire => questionnaire.status === STATUS_ACTIVE)
        .forEach(questionnaire => {
            const questionnaireId = questionnaire.id;

            map[questionnaireId] = questionnaireRenderer.render(questionnaireId);
        });

        return map;
    }, [questionnaires]);

    const questionnaire = useMemo(() => {
        return filters.options.questionnaireId
            ? idToQuestionnaire[filters.options.questionnaireId]
            : null;
    }, [idToQuestionnaire, filters.options]);

    const questionsToUse = useMemo(() => {
        const questionnaireId = questionnaire ? questionnaire.id : null;

        return questionnaireId
            ? questions.filter(question => question.questionnaireId === questionnaireId)
            : [];
    }, [questionnaire, questions]);

    const submissionByComposite = useMemo(() => {
        const map = {};

        submissions.forEach(submission => {
            const composite = `${submission.questionnaireId}:${submission.userId}`;

            map[composite] = submission;
        });

        return map;
    }, [submissions]);

    const getSubmission = (user) => {
        return questionnaire
            ? submissionByComposite[toComposite(questionnaire.id, user.id)]
            : null;
    };

    return <div className="users-page">
        <div className="field">
            <SelectField
                label="Questionnaire"
                value={forms.sanitizeValue(filters.options.questionnaireId)}
                onChange={(questionnaireId) => filters.setOptions({
                    questionnaireId: questionnaireId,
                })}
                options={idToTitle}
            />
        </div>
        <div className="field">
            <FiltersAccordion
                filters={filters}
                form={UserFilters}
                open={showFilters}
                onOpen={() => setShowFilters(true)}
                onClose={() => setShowFilters(false)}
                formProps={{
                    user: me,
                    questions: questionsToUse,
                }}
            />
        </div>
        <Cards
            items={filtered}
            paging={filters.paging}
            dimensions={dimensions}
            renderer={(user) =>
                <UserCard key={user.id} user={user} me={me}
                          questionnaire={questionnaire}
                          submission={getSubmission(user)}
                >
                    {me.roles.admin ?
                        <IconButton color="secondary" title="Delete" className="delete"
                                    disabled={me.id === user.id}
                                    onClick={() => setUser(user)}>
                            <DeleteIcon/>
                        </IconButton> : null}
                    {isMobileOnly ?
                        <IconButton color="primary" title="Call"
                                    disabled={me.id === user.id || strings.isBlank(user.phone)}
                                    onClick={() => communication.popCall(user.phone)}>
                            <CallIcon/>
                        </IconButton> : null}
                    {isMobile ?
                        <IconButton color="primary" title="Text"
                                    disabled={me.id === user.id || strings.isBlank(user.phone)}
                                    onClick={() => communication.popSms(user.phone)}>
                            <SmsIcon/>
                        </IconButton> : null}
                    <IconButton color="primary" title="Email"
                                disabled={me.id === user.id}
                                onClick={() => communication.popEmail(user.email)}>
                        <EmailIcon/>
                    </IconButton>
                    {me.roles.admin ?
                        <IconButton color="primary" title="Edit"
                                    onClick={() => me.id === user.id ? onGotoProfile() : onGotoUser(user.id)}>
                            <EditIcon/>
                        </IconButton> : null}
                </UserCard>}
        />
        {me.roles.admin ?
            <FloatingAddButton
                title="Add user"
                position="higher"
                onClick={onGotoAdd}
            /> : null}
        {user ? <ConfirmDeleteDialog
            snackbar={snackbar}
            title={userUtils.getFullName(user)}
            onCancel={() => setUser(null)}
            onDelete={(userId) => {
                setUser(null);
                onDelete(userId);
            }}
            deleter={(success, failure) => userService.deleteUser(user.id, success, failure)}
        /> : null}
    </div>
}

export default UsersPage;
